export default {
  imgIntro: require("../assets/images/img-intro.png"),
  imgProjectBanpu: require("../assets/images/img-proj-banpu.png"),
  imgProjectCmBusPos: require("../assets/images/img-proj-cmbus-pos.png"),
  imgProjectUrbanTransit: require("../assets/images/img-proj-urban-transit.png"),
  imgProjectCmTransit: require("../assets/images/img-proj-cmtransit.png"),
  imgProjectFineDae: require("../assets/images/img-proj-finedae.png"),
  imgProjectOmMerchant: require("../assets/images/img-proj-om-merchant.png"),
  imgProjectOmWallet: require("../assets/images/img-proj-om-wallet.png"),
  imgProjectOmPlatform: require("../assets/images/img-proj-omplatform.png"),
  imgProjectSell: require("../assets/images/img-proj-sell-ticket.png"),
  imgProjectSiamBlockchainPro: require("../assets/images/img-proj-siam-blockchain-pro.png"),
  imgProjectTokenization: require("../assets/images/img-proj-tokenization.png"),
  imgProjectUrbanCrown: require("../assets/images/img-proj-urbancrown.png"),
  imgProjectBanpuPower: require("../assets/images/img-proj-banpu-power.png"),
  imgProjectSCBAnywhere: require("../assets/images/img-proj-scb-anywhere.png"),
  imgProjectDLTTaxiDriver: require("../assets/images/img-proj-dlt-driver.png"),
  imgProjectHandiGo: require("../assets/images/img-proj-handigo.png"),
  imgProjectTSpace: require("../assets/images/img-proj-cross_procurement.jpeg"),
  imgProjectAsaph: require("../assets/images/img-proj-asaph.png"),
  imgProjectLandAndHouse: require("../assets/images/img-proj-lh.png"),
  imgProjectPikul: require("../assets/images/img-proj-pikul.png"),
  imgLogo: require("../assets/images/icon-logo.png"),
  imgSliderTokenization1: require("../assets/images/tokenization/img-slider-token-1.png"),
  imgSliderTokenization2: require("../assets/images/tokenization/img-slider-token-2.png"),
  imgSliderTokenization3: require("../assets/images/tokenization/img-slider-token-3.png"),
  imgSliderTokenization4: require("../assets/images/tokenization/img-slider-token-4.png"),
  imgSliderTokenization5: require("../assets/images/tokenization/img-slider-token-5.png"),
  imgSliderTokenization6: require("../assets/images/tokenization/img-slider-token-6.png"),
  imgSliderOmPlatform1: require("../assets/images/omPlatform/img-slider-om-platform-1.png"),
  imgSliderOmPlatform2: require("../assets/images/omPlatform/img-slider-om-platform-2.png"),
  imgSliderUrbanFund1: require("../assets/images/urbanfund/img-slider-urban-fund-1.png"),
  imgSliderUrbanFund2: require("../assets/images/urbanfund/img-slider-urban-fund-2.png"),
  imgSliderUrbanFund3: require("../assets/images/urbanfund/img-slider-urban-fund-3.png"),
  imgSliderUrbanFund4: require("../assets/images/urbanfund/img-slider-urban-fund-4.png"),
  imgSliderCMBUSAdmin1: require("../assets/images/cmbusAdmin/img-slider-cmbus-admin-1.png"),
  imgSliderCMBUSAdmin2: require("../assets/images/cmbusAdmin/img-slider-cmbus-admin-2.png"),
  imgSliderCMBUSAdmin3: require("../assets/images/cmbusAdmin/img-slider-cmbus-admin-3.png"),
  imgSliderCMBUSAdmin4: require("../assets/images/cmbusAdmin/img-slider-cmbus-admin-4.png"),
  imgSliderSmartSignage1: require("../assets/images/smartSignage/img-slider-smart-signage-1.png"),
  imgSliderSmartSignage2: require("../assets/images/smartSignage/img-slider-smart-signage-2.png"),
  imgSliderSmartSignage3: require("../assets/images/smartSignage/img-slider-smart-signage-3.png"),
  imgSliderSmartSignage4: require("../assets/images/smartSignage/img-slider-smart-signage-4.png"),
  imgSliderSmartSignage5: require("../assets/images/smartSignage/img-slider-smart-signage-5.png"),
  imgSliderUrbanTransit1: require("../assets/images/urbanTransit/img-slider-urban-transit-1.png"),
  imgSliderUrbanTransit2: require("../assets/images/urbanTransit/img-slider-urban-transit-2.png"),
  imgSliderUrbanTransit3: require("../assets/images/urbanTransit/img-slider-urban-transit-3.png"),
  imgSliderUrbanTransit4: require("../assets/images/urbanTransit/img-slider-urban-transit-4.png"),
  imgSliderUrbanTransit5: require("../assets/images/urbanTransit/img-slider-urban-transit-5.png"),
  imgSliderUrbanTransit6: require("../assets/images/urbanTransit/img-slider-urban-transit-6.png"),
  imgSliderUrbanTransit7: require("../assets/images/urbanTransit/img-slider-urban-transit-7.png"),
  imgSliderUrbanTransit8: require("../assets/images/urbanTransit/img-slider-urban-transit-8.png"),
  imgSliderOmWallet1: require("../assets/images/omWallet/img-slider-om-wallet-1.png"),
  imgSliderOmWallet2: require("../assets/images/omWallet/img-slider-om-wallet-2.png"),
  imgSliderOmWallet3: require("../assets/images/omWallet/img-slider-om-wallet-3.png"),
  imgSliderOmWallet4: require("../assets/images/omWallet/img-slider-om-wallet-4.png"),
  imgSliderOmWallet5: require("../assets/images/omWallet/img-slider-om-wallet-5.png"),
  imgSliderOmWallet6: require("../assets/images/omWallet/img-slider-om-wallet-6.png"),
  imgSliderOmMerchant1: require("../assets/images/omMerchant/img-slider-ommerchant-1.png"),
  imgSliderOmMerchant2: require("../assets/images/omMerchant/img-slider-ommerchant-2.png"),
  imgSliderOmMerchant3: require("../assets/images/omMerchant/img-slider-ommerchant-3.png"),
  imgSliderOmMerchant4: require("../assets/images/omMerchant/img-slider-ommerchant-4.png"),
  imgSliderOmMerchant5: require("../assets/images/omMerchant/img-slider-ommerchant-5.png"),
  imgSliderOmMerchant6: require("../assets/images/omMerchant/img-slider-ommerchant-6.png"),
  imgSliderOmMerchant7: require("../assets/images/omMerchant/img-slider-ommerchant-7.png"),
  imgSliderCMBUSPOS1: require("../assets/images/cmbusPOS/img-slider-cmbus-1.png"),
  imgSliderCMBUSPOS2: require("../assets/images/cmbusPOS/img-slider-cmbus-2.png"),
  imgSliderCMBUSPOS3: require("../assets/images/cmbusPOS/img-slider-cmbus-3.png"),
  imgSliderCMBUSPOS4: require("../assets/images/cmbusPOS/img-slider-cmbus-4.png"),
  imgSliderCMBUSPOS5: require("../assets/images/cmbusPOS/img-slider-cmbus-5.png"),
  imgSliderCMBUSPOS6: require("../assets/images/cmbusPOS/img-slider-cmbus-6.png"),
  imgSliderCMBUSPOS7: require("../assets/images/cmbusPOS/img-slider-cmbus-7.png"),
  imgSliderCMBUSPOS8: require("../assets/images/cmbusPOS/img-slider-cmbus-8.png"),
  imgSliderFineDae1: require("../assets/images/finedae/img-slider-finedae-1.png"),
  imgSliderFineDae2: require("../assets/images/finedae/img-slider-finedae-2.png"),
  imgSliderFineDae3: require("../assets/images/finedae/img-slider-finedae-3.png"),
  imgSliderSiamBlockChain1: require("../assets/images/siamBlockChain/img-slider-siamblockchain-1.png"),
  imgSliderSiamBlockChain2: require("../assets/images/siamBlockChain/img-slider-siamblockchain-2.png"),
  imgSliderSiamBlockChain3: require("../assets/images/siamBlockChain/img-slider-siamblockchain-3.png"),
  imgSliderSiamBlockChain4: require("../assets/images/siamBlockChain/img-slider-siamblockchain-4.png"),
  imgSliderSiamBlockChain5: require("../assets/images/siamBlockChain/img-slider-siamblockchain-5.png"),
  imgSliderSiamBlockChain6: require("../assets/images/siamBlockChain/img-slider-siamblockchain-6.png"),
  imgSliderSiamBlockChain7: require("../assets/images/siamBlockChain/img-slider-siamblockchain-7.png"),
  imgSliderSiamBlockChain8: require("../assets/images/siamBlockChain/img-slider-siamblockchain-8.png"),
  imgSliderSiamBlockChain9: require("../assets/images/siamBlockChain/img-slider-siamblockchain-9.png"),
  imgSliderBanPu1: require("../assets/images/banpu/img-slider-banpu-1.png"),
  imgSliderBanPu2: require("../assets/images/banpu/img-slider-banpu-2.png"),
  imgSliderBanPu3: require("../assets/images/banpu/img-slider-banpu-3.png"),
  imgSliderBanPu4: require("../assets/images/banpu/img-slider-banpu-4.png"),
  imgSliderBanPu5: require("../assets/images/banpu/img-slider-banpu-5.png"),
  imgSliderBanPuPower1: require("../assets/images/banpuPower/img-slider-banpu-power-1.png"),
  imgSliderBanPuPower2: require("../assets/images/banpuPower/img-slider-banpu-power-2.png"),
  imgSliderBanPuPower3: require("../assets/images/banpuPower/img-slider-banpu-power-3.png"),
  imgSliderBanPuPower4: require("../assets/images/banpuPower/img-slider-banpu-power-4.png"),
  imgSliderBanPuPower5: require("../assets/images/banpuPower/img-slider-banpu-power-5.png"),
  imgSliderDLTTaxiDriver1: require("../assets/images/dltTaxiDriver/img-slider-dlttaxi-1.png"),
  imgSliderDLTTaxiDriver2: require("../assets/images/dltTaxiDriver/img-slider-dlttaxi-2.png"),
  imgSliderDLTTaxiDriver3: require("../assets/images/dltTaxiDriver/img-slider-dlttaxi-3.png"),
  imgSliderDLTTaxiDriver4: require("../assets/images/dltTaxiDriver/img-slider-dlttaxi-4.png"),
  imgSliderScbAnyWhere1: require("../assets/images/scbAnyWhere/img-slider-scb-anywhere-1.png"),
  imgSliderScbAnyWhere2: require("../assets/images/scbAnyWhere/img-slider-scb-anywhere-2.png"),
  imgSliderScbAnyWhere3: require("../assets/images/scbAnyWhere/img-slider-scb-anywhere-3.png"),
  imgSliderScbAnyWhere4: require("../assets/images/scbAnyWhere/img-slider-scb-anywhere-4.png"),
  imgSliderHandiGo1: require("../assets/images/handiGo/img-slider-handigo-1.png"),
  imgSliderHandiGo2: require("../assets/images/handiGo/img-slider-handigo-2.png"),
  imgSliderHandiGo3: require("../assets/images/handiGo/img-slider-handigo-3.png"),
  imgSliderAsaph1: require("../assets/images/asaph/img-slider-asaph-1.png"),
  imgSliderAsaph2: require("../assets/images/asaph/img-slider-asaph-2.png"),
  imgSliderAsaph3: require("../assets/images/asaph/img-slider-asaph-3.png"),
  imgSliderAsaph4: require("../assets/images/asaph/img-slider-asaph-4.png"),
  imgSliderAsaph5: require("../assets/images/asaph/img-slider-asaph-5.png"),
  imgSliderAsaph6: require("../assets/images/asaph/img-slider-asaph-6.png"),
  imgSliderAsaph7: require("../assets/images/asaph/img-slider-asaph-7.png"),
  imgSliderTSpace1: require("../assets/images/tspace/img-slider-tspace-1.png"),
  imgSliderTSpace2: require("../assets/images/tspace/img-slider-tspace-2.png"),
  imgSliderTSpace3: require("../assets/images/tspace/img-slider-tspace-3.png"),
  imgSliderTSpace4: require("../assets/images/tspace/img-slider-tspace-4.png"),
  imgSliderTSpace5: require("../assets/images/tspace/img-slider-tspace-5.png"),
  imgSliderTSpace6: require("../assets/images/tspace/img-slider-tspace-6.png"),
  imgSliderLHCareer1: require("../assets/images/lhCareer/img-slider-lh-career-1.png"),
  imgSliderLHCareer2: require("../assets/images/lhCareer/img-slider-lh-career-2.png"),
  imgSliderLHCareer3: require("../assets/images/lhCareer/img-slider-lh-career-3.png"),
  imgSliderLHCareer4: require("../assets/images/lhCareer/img-slider-lh-career-4.png"),
  imgSliderLHCareer5: require("../assets/images/lhCareer/img-slider-lh-career-5.png"),
  imgSliderLHJobForSale1: require("../assets/images/lhJobSale/img-slider-lh-job-sale-1.png"),
  imgSliderLHJobForSale2: require("../assets/images/lhJobSale/img-slider-lh-job-sale-2.png"),
  imgSliderLHJobForSale3: require("../assets/images/lhJobSale/img-slider-lh-job-sale-3.png"),
  imgSliderLHJobForSale4: require("../assets/images/lhJobSale/img-slider-lh-job-sale-4.png"),
  imgSliderCraft20241: require("../assets/images/craft2024/img-slider-craft-2024-1.png"),
  imgSliderCraft20242: require("../assets/images/craft2024/img-slider-craft-2024-2.png"),
  imgSliderCraft20243: require("../assets/images/craft2024/img-slider-craft-2024-3.png"),
  imgSliderCraft20244: require("../assets/images/craft2024/img-slider-craft-2024-4.png"),
  imgSliderCraft20245: require("../assets/images/craft2024/img-slider-craft-2024-5.png"),
  imgSliderPikul: require("../assets/images/pikul/img-slider-pikul-1.png"),
};
